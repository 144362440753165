import React from "react";
import CustomPopover from "./CustomPopover";

const popovers = [
  {
    popoverHeader: "DOI analysis",
    popoverBody: (
      <>
        This signal analyses references in the manuscript.
        <br />
        &#x25cf; The first number signifies the number of DOIs listed in the
        reference list that do not exist (via check with DOI.org).
        <br />
        &#x25cf; The second number signifies the total number of DOIs that were
        listed in the reference list or could be retrieved using our software.
        <br />
        &#x25cf; The number in brackets is the total number of references that
        were found in the manuscript.
      </>
    ),
  },
  {
    popoverHeader: "PubPeer hits",
    popoverBody: (
      <>
        This signal checks if references in the manuscript received mentions in
        the PubPeer database, an indication of being problematic (aka
        Feet-of-Clay tool).
        <br />
        &#x25cf; The first number displays the number of references that were
        marked as retracted.
        <br />
        &#x25cf; The second number displays the number of references that
        received comments.
        <br />
        &#x25cf; The third number signifies the total number of DOIs that were
        listed in the reference list or could be retrieved using our software
        and were sent off to PubPeer.
        <br />
        &#x25cf; The number in brackets is the total number of references that
        were found in the manuscript.
      </>
    ),
  },
  {
    popoverHeader: "Retraction Watch",
    popoverBody: (
      <>
        This signal checks if references are present in the Retraction Watch
        database
        <br />
        &#x25cf; The first number displays the number of references found in
        Retraction Watch database.
        <br />
        &#x25cf; The number in brackets is the total number of references found
        in the manuscript.
      </>
    ),
  },
  {
    popoverHeader: "Papermill similarity",
    popoverBody: (
      <>
        'Papermill Alarm: Public' service provided by Clear&nbsp;Skies&nbsp;Ltd.
        Papermill Alarm: Public is a limited version of the multi-award-winning Papermill Alarm.
        Both versions of the Papermill Alarm use network analysis and artificial intelligence to
        identify patterns in content, authorship, and referencing consistent with organised research misconduct.
        There are currently 9 integrity checks within the Papermill Alarm.
        Further details can be found at <a href="https://clear-skies.co.uk/" target="_blank" rel="noopener noreferrer">
           https://clear-skies.co.uk/.<br />
        </a>
        An alert is triggered when patterns are found to match known papermill-products.<br />
        Alert ratings are: <br />
        &#x25cf; 'red' - high similarity to known papermill-products;
        <br />
        &#x25cf; 'orange' - some similarity to known papermill-products;
        <br />
        &#x25cf; 'green' - no significant similarity to known papermill-products.
        <br />
        Alerts do not constitute evidence of misconduct and Clear&nbsp;Skies does not make any judgment as to
        whether misconduct has taken place. Clear&nbsp;Skies recommends that all submissions receiving red or
        orange alerts are checked by specialists.
      </>
    ),
  },
  {
    popoverHeader: "Tortured phrases",
    popoverBody: (
      <>
        The text of the manuscript is checked against thousands of phrases that
        were identified by publishers and Dr. Guillame Cabanac et al. as likely
        being AI-generated or rewritten. The database is refreshed regularly.
      </>
    ),
  },
  {
    popoverHeader: "Unnatural text",
    popoverBody: (
      <>
        GPT-2 Detection — This tool was developed by Guillame Cabanac et al. It
        returns a percentage that is a measure for the likelihood that a paper
        is machine-generated or re-written based on the occurrence of tortured
        phrases in the text.
      </>
    ),
  },
  {
    popoverHeader: "Fake affiliation names",
    popoverBody: (
      <>
        Watchlist — The STM Integrity Hub maintains a list of fake email domains
        and institution names that were involved in paper mill activities. A red
        flag indicates that a match was found.
      </>
    ),
  },
  {
    popoverHeader: "Fake names or e-mail addresses",
    popoverBody: (
      <>
        Watchlist — The STM Integrity Hub maintains a list of fake names or
        email adresses that were involved in paper mill activities. A red flag
        indicates that a match was found.
      </>
    ),
  },
  {
    popoverHeader: "Fake e-mail domains",
    popoverBody: (
      <>
        Watchlist — The Hub maintains a list of fake email domains that were
        involved in paper mill activities. Domains are sourced from publishers
        and Anna Abalkina. A red flag indicates that a match was found.
      </>
    ),
  },
  {
    popoverHeader: "Disposable e-mail domains",
    popoverBody: (
      <>
        Watchlist — A list of disposable and temporary email address domains
        often used to register dummy users in order to spam or abuse some
        services. See&nbsp;
        <a href="https://github.com/disposable-email-domains/disposable-email-domains">
          https://github.com/disposable-email-domains/disposable-email-domains
        </a>
        .
      </>
    ),
  },
  {
    popoverHeader: "Watchlist",
    popoverBody: (
      <>
        Watchlist — The STM Integrity Hub maintains a list of fake email domains
        and institution names that were involved in paper mill activities. A red
        flag indicates that a match was found.
      </>
    ),
  },
  {
    popoverHeader: "Reference Issues",
    popoverBody: (
      <>
        Checks for citation cartels, self-citations, old references, references
        in the reference list not cited in the text, and citations to unrelated
        research.
      </>
    ),
  },
  {
    popoverHeader: "Authorship Issues",
    popoverBody: (
      <>
        Checks for a lack of relevance between the affiliation(s) and the field
        of study discussed in the manuscript, and author affiliations from three
        or more countries.
      </>
    ),
  },
  {
    popoverHeader: "GenAI response",
    popoverBody: (
      <>
        A collection of phrases that indicate the use of GenAI. The database is
        maintained by by Dr. Guillaume Cabanac.
      </>
    ),
  },
];

const SignalInfoPopover = ({ signal, placement = "right" }) => {
  const { popoverHeader, popoverBody } =
    popovers.find((element) => element.popoverHeader === signal) ?? {};

  return (
    <CustomPopover
      popoverHeader={popoverHeader}
      popoverBody={popoverBody}
      placement={placement}
    >
      <span>
        <i className="fa fa-info-circle fa-stack-1x info_circle ms-1"></i>
      </span>
    </CustomPopover>
  );
};

export default SignalInfoPopover;
